import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axiosInstance from "../../api/axios";
import { tokens } from "../../theme";
import InfoIcon from "@mui/icons-material/Info";
import { useSnackbar } from "notistack";
import InfractionChargeSelect from "./InfractionChareSelect";
//
const IncidentReportForm = ({ onClose, selectedVideo }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    incidentDate: "",
    adminUsername: "",
    incidentDescription: "",
    infractionCharge: "",
    offender_username: selectedVideo?.username || "",
    post_id: selectedVideo?._id || "",
    location: "",
    names_and_roles: "",
    actions: "",
    incident_trigger: "",
    contributing_factors: "",
    escalation: "",
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedVideo) {
      console.log("Selected Video:", selectedVideo);
      setFormData((prevData) => ({
        ...prevData,
        offender_username: selectedVideo.username || "",
        postId: selectedVideo._id || "",
      }));
    }
  }, [selectedVideo]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setFormData((prevData) => ({
      ...prevData,
      incidentDate: today,
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

   const handleSelectChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      infractionCharge: e.target.value,
    }));
  };

  const validateForm = () => {
    const requiredFields = [
      "incidentDate",
      "adminUsername",
      "incidentDescription",
      "infractionCharge",
      "location",
      "names_and_roles",
      "actions",
      "incident_trigger",
      "contributing_factors",
      "escalation",
    ];

    // Check if all required fields are filled
    const isValid = requiredFields.every(
      (field) => formData[field]?.trim() !== ""
    );
    setIsFormValid(isValid); // Update form validity state
  };

  useEffect(() => {
    validateForm(); // Revalidate form whenever formData changes
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(
        "api/support/submitIncident",
        formData
      );
      console.log("Server Response:", response); // Log the entire response for debugging

      // Check if the response is successful
      if (response.status === 200 && response.data.status === "success") {
        enqueueSnackbar(response.data.message, { variant: "success" });

        // Reset form fields
        setFormData({
          incidentDate: "",
          adminUsername: "",
          incidentDescription: "",
          infractionCharge: "",
          location: "",
          names_and_roles: "",
          actions: "",
          incident_trigger: "",
          contributing_factors: "",
          escalation: "",
        });

        // Close the modal
        onClose();
      } else {
        // Handle failure response
        enqueueSnackbar(
          response.data.message ||
            "Failed to submit the report. Please try again.",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      enqueueSnackbar(
        "An error occurred while submitting the report. Please try again.",
        { variant: "error" }
      );
    }
  };

  const inputStyles = {
    marginBottom: 2,
    "& .MuiInputBase-input": {
      color: "white", // Keep text white
    },
    "& .MuiInputLabel-root": {
      color: "white", // Keep label white
    },
    "& .MuiInputBase-root": {
      backgroundColor: "black", // Optional: Set background color to black
    },
    "& .MuiInputBase-input::placeholder": {
      color: "white", // Keep placeholder text white
    },
    "& .Mui-focused .MuiInputLabel-root": {
      color: "white", // Keep label white when focused
    },
  };

  const infoStyles = {
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
    zIndex: 1,
    p: 0,
    width: 24,
    height: 24,
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: "800px",
        bgcolor: "black",
        p: 3,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        boxShadow: 2,
        maxHeight: "80vh", // Set the max height for the form
        overflowY: "auto",
      }}
    >
      <Typography
        color="error"
        variant="h5"
        textAlign="center"
        fontWeight="bold"
      >
        Incident Report
      </Typography>
      <Divider sx={{ mb: 1 }} />
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <Tooltip
          title="Select the date the incident occurred, this could be different than the original report date"
          arrow
        >
          <IconButton sx={infoStyles}>
            <InfoIcon sx={{ color: theme.palette.neutral.main }} />
          </IconButton>
        </Tooltip>
        <TextField
          label="Date of Incident"
          name="incidentDate"
          value={formData.incidentDate}
          onChange={handleChange}
          type="date"
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={inputStyles}
        />
      </Box>
      <TextField
        label="Offender"
        value={formData.offender_username} // Display the username
        disabled
        fullWidth
        sx={inputStyles}
      />
      <TextField
        label="Post ID"
        value={formData.post_id} // Display the username
        disabled
        fullWidth
        sx={inputStyles}
      />

      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Report by: Enter name of the admin who is creating the incident report"
          name="adminUsername"
          value={formData.adminUsername}
          onChange={handleChange}
          required
          fullWidth
          sx={inputStyles}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Location: Enter location of incident: Video Feed, Comment section, Messenger"
          name="location"
          value={formData.location}
          onChange={handleChange}
          required
          fullWidth
          sx={inputStyles}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Enter contributing factors that led up to the incident. (e.g, stress, malfunctions)?"
          name="contributing_factors"
          value={formData.contributing_factors}
          onChange={handleChange}
          multiline
          rows={3}
          required
          fullWidth
          sx={inputStyles}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Names & Roles: Enter all names involved with report and all the roles they played in the incident"
          name="names_and_roles"
          value={formData.names_and_roles}
          onChange={handleChange}
          multiline
          rows={3}
          required
          fullWidth
          sx={inputStyles}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Actions: Describe what each person did during the incident"
          name="actions"
          value={formData.actions}
          onChange={handleChange}
          multiline
          rows={5}
          required
          fullWidth
          sx={inputStyles}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Trigger: What caused the incident?"
          name="incident_trigger"
          value={formData.incident_trigger}
          onChange={handleChange}
          multiline
          rows={5}
          required
          fullWidth
          sx={inputStyles}
        />
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Description: Provide a comprehensive description of the incident."
          name="incidentDescription"
          value={formData.incidentDescription}
          onChange={handleChange}
          required
          multiline
          rows={5}
          fullWidth
          sx={inputStyles}
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <TextField
          label="Escalation: How did the situation worsen, if applicable"
          name="escalation"
          value={formData.escalation}
          onChange={handleChange}
          required
          multiline
          rows={5}
          fullWidth
          sx={inputStyles}
        />
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <Tooltip
          title="Please select the infraction charge for the incident. It must be listed in our Community Guidelines for it to be counted as an infraction"
          arrow
        >
          <IconButton sx={infoStyles}>
            <InfoIcon sx={{ color: theme.palette.neutral.main }} />
          </IconButton>
        </Tooltip>
        <FormControl fullWidth sx={{ mb: 2 }}>
        <InfractionChargeSelect
        value={formData.infractionCharge}
        onChange={handleSelectChange}
        inputStyles={inputStyles}
      />
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          sx={{
            width: "100px",
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!isFormValid}
          sx={{
            width: "100px",
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default IncidentReportForm;

import { useState } from "react";
import { BoxContainer, RegisterBox, StyledTextField } from "../../styles/Container.Styled";
import { Button, Typography, Box, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Alert from "@mui/material/Alert";

import axiosFormDataInstance from "../../api/axiosFormData";
import AudioUploader from "./AudioUploader";
import ImageUploader from "./ImageUploader";

const ADD_SOUNDS_URL = "api/sounds/uploadSounds";

const AddSounds = () => {
  const [artist, setArtist] = useState("");
  const [song_name, setSong_name] = useState("");
  const [link, setLink] = useState("");
  const [duration, setDuration] = useState("");
  const [author_link, setAuthor_link] = useState("");
  const [genre, setGenre] = useState("");
  const [label, setLabel] = useState("");
  const [release_date, setRelease_date] = useState("");
  const [success, setSuccess] = useState(false);
  const [audioImage, setAudioImage] = useState(null);
  const [audio, setAudio] = useState(null);

  const successMessage = () => {
    setSuccess(true);
  }

  function resetTextInput() {
    setArtist("");
    setSong_name("");
    setLink("");
    setDuration("");
    setAuthor_link("");
    setGenre("");
    setLabel("");
    setRelease_date("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("artist", artist);
    formData.append("song_name", song_name);
    formData.append("link", link);
    formData.append("duration", duration);
    formData.append("author_link", author_link);
    formData.append("genre", genre);
    formData.append("label", label);
    formData.append("release_date", release_date);
    formData.append("audio", audio);
    formData.append("image", audioImage);
    axiosFormDataInstance
      .post(ADD_SOUNDS_URL, formData)
      .then(async function () {
        resetTextInput();
        successMessage();
      })
      .catch(function (e) {
        console.log("Error registering", e);
      });
  };

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  
  return (
    <>

      <BoxContainer>
        <section>
          <Box sx={{ p: 2, pb: 5 }}>
            <Typography align="center" paddingTop={3}>
            </Typography>
            <Typography
              lineHeight={1.6}
              variant="h3"
              align="center"
              mb={2}
              pb={5}
            >
              Sounds Registration
            </Typography>
            <RegisterBox className="loginBox">
            <form encType="multipart/form-data" className="loginForm" onSubmit={handleSubmit}>
            
          { success ? <Alert sx={{pt: 2, pb: 2,}} icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
        Data entry successful! 
      </Alert> : null}
      <Box sx={{mb: 5}}>
        <AudioUploader setAudio={setAudio} />
      </Box>
      <ImageUploader setAudioImage={setAudioImage} />
            <StyledTextField
                helperText="Artist Name"
                label="Artist"
                value={artist}
                onChange={(e) => setArtist(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Song Name"
                label="Song Title"
                value={song_name}
                onChange={(e) => setSong_name(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Duration"
                label="Duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Genre"
                label="Genre"
                value={genre}
                onChange={(e) => setGenre(e.target.value)}
                margin="normal"
                required
              />
              <StyledTextField
                helperText="Artist Buy Link"
                label="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                margin="normal"
              />
              <StyledTextField
                helperText="Author Buy Link"
                label="Author Link"
                value={author_link}
                onChange={(e) => setAuthor_link(e.target.value)}
                margin="normal"
              />
              
              <StyledTextField
                helperText="Record Label"
                label="Record Label"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                margin="normal"
              />
              <StyledTextField
              sx={{mb: 5}}
                helperText="Release Date"
                label="Release Date"
                value={release_date}
                onChange={(e) => setRelease_date(e.target.value)}
                margin="normal"
                required
              />
              
              <Button
              disableRipple
              variant="contained"
              type="submit"
              sx={{ 
                mt: 2, p: 2,
                ":hover": {
                  bgcolor: "#1F2A40",
                  color: "white" 
                }
              }}
            >
              Add Song
            </Button>
            </form>
            </RegisterBox>
            
          </Box>
        </section>
      </BoxContainer>

    </>
  )
}

export default AddSounds;